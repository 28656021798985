import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import Hero from 'components/pg-contact/Hero'
import Contact from 'components/pg-contact/Contact'
import { graphql, useStaticQuery } from 'gatsby'

const ContactPg = () => {
  const { sanitySite, sanityContactPg: pg } = useStaticQuery(query)

  return (
    <DefaultLayout {...pg.seo}>
      <Hero
        title={pg.title}
        site={sanitySite}
        image={pg.hero.image}
      />
      <Contact {...pg.hero} />
    </DefaultLayout>
  )
}

export default ContactPg

const query = graphql`query ContactPg {
  sanitySite {
    contact {
      tel
      email
    }
    address
  }
  sanityContactPg {
    title
    hero { ...hero }
    seo {
      title
      description
    }
  }
}`
