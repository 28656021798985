import * as React from 'react'

const nl2br = str => {
  const lines = str.split(/\n/g)

  return lines.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== (lines.length - 1) && <br/>}
    </React.Fragment>
  ))
}

export default nl2br
