import * as React from 'react'
import * as css from './Hero.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FaPhone, FaMapMarkerAlt } from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'
import nl2br from 'utils/nl2br'
import Social from 'components/common/Social'

const Hero = ({ title, site, image }) => (
  <section className={css.root}>
    <div className="grid center container">
      <div className={`${css.txt} padding`}>
        <h1 className="h1">{title}</h1>

        <nav className={css.contact}>
          <div>
            <a className={css.withIcon} href={`tel:${site.contact.tel}`}>
              <FaPhone className={css.icon} />
              {site.contact.tel.replace(/-/g, '.')}
            </a>
          </div>
          <div>
            <a className={css.withIcon} href={`mailto:${site.contact.email}`}>
              <IoIosMail className={css.icon} />
              {site.contact.email.replace(/-/g, '.')}
            </a>
          </div>
          <address className={css.withIcon}>
            <FaMapMarkerAlt className={css.icon} />
            {nl2br(site.address)}
          </address>
        </nav>

        <hr />

        <Social className={css.social} />
      </div>

      <GatsbyImage
        className={css.img}
        image={getImage(image.asset)}
        alt={title}
      />
    </div>
  </section>
)

export default Hero
