import * as React from 'react'
import QuickLinks from 'components/common/QuickLinks'
import SanityBlock from 'components/common/SanityBlock'

const Contact = ({ title, _rawDescription }) => (
  <QuickLinks>
    <h2 className="h1">{title}</h2>
    <SanityBlock body={_rawDescription} />
  </QuickLinks>
)

export default Contact
